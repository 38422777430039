.EmptyState {
  @apply mx-auto flex max-w-[379px] flex-col items-center justify-center py-8 text-center;

  & svg .backgroundAndBorder {
    @apply fill-neutral-100 stroke-neutral-200 dark:fill-neutral-700 dark:stroke-neutral-800;
  }

  & svg .lighterFillColor {
    @apply fill-neutral-100 dark:fill-neutral-700;
  }

  & svg .darkerFillColor {
    @apply fill-neutral-200 dark:fill-neutral-800;
  }

  & svg .background {
    @apply fill-white dark:fill-neutral-900;
  }

  & svg .popCorns {
    @apply fill-neutral-100 stroke-neutral-200 stroke-2 dark:fill-neutral-600 dark:stroke-neutral-800;
  }

  & svg .candy {
    @apply fill-white dark:fill-neutral-600;
  }

  &.dark svg .backgroundAndBorder {
    @apply fill-neutral-700 stroke-neutral-800;
  }

  &.dark svg .lighterFillColor {
    @apply fill-neutral-700;
  }

  &.dark svg .darkerFillColor {
    @apply fill-neutral-800;
  }

  &.dark svg .background {
    @apply fill-neutral-900;
  }

  &.dark svg .popCorns {
    @apply fill-neutral-600 stroke-neutral-800;
  }

  &.dark svg .candy {
    @apply fill-neutral-600;
  }
}

/* Error */
.errorTitle {
  @apply h2 sm:h1;
}

.errorCopy {
  @apply pb-4 sm:text-lg;
}
