.content {
  @apply relative z-30 mx-auto -mt-12 max-w-2xl space-y-4 md:mb-12 md:text-center;
}

.tagLine {
  @apply h2 mb-2 md:h1 md:mb-auto;
}

.tagLineSupport {
  @apply callout hidden md:block;
}

.filters {
  @apply grid w-full grid-cols-1 gap-8 lg:grid-cols-2;
  @media (min-width: theme('screens.xl')) {
    grid-template-columns: calc(calc(100% / var(--billboard-cols) * 2) - theme('spacing.8')) calc(
        calc(100% / var(--billboard-cols) * 3)
      );
  }
}
