.root {
  @apply relative rounded-xl text-white shadow-xl brightness-100 transition-all duration-300 dark:text-neutral-600;
}

:global(a:hover) .root {
  @apply brightness-[1.3];
}

.shadow {
  @apply relative top-1 h-full w-full opacity-60 blur blur-sm transition-all md:top-1;
}

.img {
  @apply overflow-hidden rounded-xl object-cover;
}
