.root {
  @apply relative aspect-banner-mobile bg-accent md:aspect-banner;
}

.banner {
  @apply relative h-full w-full text-neutral-400;
}

.dots {
  @apply absolute inset-x-0 bottom-[50px] z-20 hidden items-center justify-center space-x-3
  py-4 md:flex;
}

.dot {
  @apply h-[16px] w-[16px] cursor-pointer rounded-full bg-primary opacity-50 hover:opacity-80;
}

.selectedDot {
  @apply opacity-100;
}

.image {
  @apply pointer-events-none h-full w-full select-none  object-cover;
}

.picture {
  @apply absolute inset-0;
}
