.root {
  @apply relative;
}

.block {
  @apply pointer-events-none absolute inset-0 z-50 flex flex-col items-center justify-center space-y-3
  opacity-0 transition-opacity duration-300;

  & .default {
    @apply bg-white/90 text-base dark:bg-neutral-800/90;
  }

  & .light {
    @apply bg-white/90 text-neutral-800;
  }

  & .dark {
    @apply bg-neutral-800/90 text-white;
  }
}

.inline {
  @apply inline-flex items-center space-x-3;

  &.default {
    @apply text-base;
  }

  &.light {
    @apply text-neutral-800;
  }

  &.dark {
    @apply text-white;
  }
}

.visible {
  & .block {
    @apply pointer-events-auto opacity-100;
  }

  & .default {
    @apply bg-white/90 text-base dark:bg-neutral-800/90;
  }

  & .light {
    @apply bg-white/90 text-neutral-800;
  }

  & .dark {
    @apply bg-neutral-800/90 text-white;
  }
}

/* Sizes */
.normal {
}

.large {
  & .block,
  &.inline {
    @apply text-3xl;
  }
}

.small {
  & .block,
  &.inline {
    @apply text-sm;
  }
}
