.root {
  --schedules: 0;
  @apply mt-6 md:mt-12;

  @media (max-width: theme('screens.sm')) {
    .schedule:nth-child(3n + 1):nth-last-child(-n + 3),
    :global(.movie):nth-child(6n + 1):nth-last-child(-n + 6) ~ .schedule {
      margin-bottom: -80px;
    }
  }

  @media (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
    --billboard-cols: 4;
    .schedule:nth-child(4n + 1):nth-last-child(-n + 4),
    :global(.movie):nth-child(8n + 1):nth-last-child(-n + 8) ~ .schedule {
      margin-bottom: -80px;
    }
  }

  @media (min-width: theme('screens.lg')) {
    --billboard-cols: 5;
    .schedule:nth-child(5n + 1):nth-last-child(-n + 5),
    :global(.movie):nth-child(10n + 1):nth-last-child(-n + 10) ~ .schedule {
      margin-bottom: -80px;
    }
  }
}

.schedule {
  @apply relative mb-4 hidden max-h-0 min-h-[400px] overflow-hidden bg-black transition-all full-bleed md:mb-12;

  &:after {
    @apply absolute left-0 top-0 h-full w-1/2 bg-gradient-to-r from-black to-black/0 content-[''];
  }
}

.background {
  @apply pointer-events-none  scale-105 select-none bg-black object-cover opacity-50 blur;
}

.movieSelected {
  @apply relative;

  &:after {
    @apply absolute bottom-0 bottom-[-23px] z-20 block h-[16px] bg-main content-[''] full-bleed;
    width: 200vw;
    clip-path: polygon(
      0 100%,
      calc(50% - 16px) 100%,
      50% 0,
      calc(50% + 16px) 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
}

.movieSelected + .schedule {
  @apply block max-h-[200000px];
  grid-column-start: 1;
  grid-column-end: calc(var(--billboard-cols) + 1);
}

.movie {
  @apply relative;
}

.premiereTag {
  @apply absolute left-0 top-3 rounded-r bg-secondary px-4 text-black;
}
