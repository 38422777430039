.root {
  --carousel-index: 4;
  --carousel-items: 0;
  --carousel-tx: 0px;
  --carousel-duration-factor: 1;
  --carousel-duration: 0.5s;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  &:not(.fade) {
    & .wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      white-space: nowrap;
      width: calc(var(--carousel-items) * 100%);
      transform: translate(
        calc(var(--carousel-index) / var(--carousel-items) * -100% + var(--carousel-tx, 0px))
      );

      .item {
        position: relative;
        width: calc(var(--carousel-items) * 100%);
      }
    }

    &.smooth .wrapper {
      transition: transform calc(var(--carousel-duration-factor, 1) * var(--carousel-duration))
        ease-out;
    }
  }

  &.fade {
    .wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .item {
      --carousel-item-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: max(
        calc(
          min(
              calc(var(--carousel-item-index) - var(--carousel-index)),
              calc(var(--carousel-index) - var(--carousel-item-index))
            ) + 1
        ),
        0
      );
    }

    &.smooth .item {
      transition-property: opacity;
      transition-duration: var(--carousel-duration);
    }
  }
}

.swipeLeft {
}
